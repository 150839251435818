import type { GameProductShort, SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'

import { CATEGORIES_CODE } from 'constants/categories'

export const getCategoryByCode = (categories: SwimlaneCategory[], categoryCode: string) => {
  return categories.find(({ code }) => code === categoryCode)
}

export const getGamesByCategoryCode = (categories: SwimlaneCategory[], categoryCode: string) => {
  return getCategoryByCode(categories, categoryCode)?.products || []
}

export const getCategoryProducts = (categories: SwimlaneCategory[]) => {
  return categories.reduce((acc, category) => {
    acc.push(...category.products)

    return acc
  }, [])
}

export const hasGameWithCode = (games: GameProductShort[], gameCode: string) =>
  !!games.find(({ code }) => code === gameCode)

export const isGameAlreadyPlayed = (recentlyPlayedGames: GameProductShort[], gameProductCode: string) =>
  recentlyPlayedGames?.some(({ code }) => code === gameProductCode)

export const filterOutCurrentGame = (currentGameId: string, category: SwimlaneCategory) => {
  if (!currentGameId) {
    return category
  } else {
    return {
      ...category,
      products: category.products.filter((product) => currentGameId !== product.route),
    }
  }
}
export const filterRecommendedCategoryByCurrentGame = (categories: SwimlaneCategory[], currentGameId: string) => {
  if (!currentGameId) {
    return categories
  }

  return categories.map((category) => {
    if (category.code === CATEGORIES_CODE.recommend) {
      return filterOutCurrentGame(currentGameId, category)
    }

    return category
  })
}
