import { createSelector } from '@reduxjs/toolkit'

import { getVisibleGames } from 'pageContainers/SearchPage/utils/getVisibleGames'
import type { ReduxState } from 'store'
import { filterOutCurrentGame, hasGameWithCode } from 'store/modules/categoryGames/utils'

const getCategoryGames = (state: ReduxState) => state.categoryGames

export const getRecommendedGamesCategory = (state: ReduxState) => getCategoryGames(state).recommendedGames
export const getNewGamesCategory = (state: ReduxState) => getCategoryGames(state).newGames

export const getRecommendedGamesSelector = createSelector(
  getCategoryGames,
  (categoryGames) => categoryGames.recommendedGames?.products || []
)

export const getFavoriteGamesSelector = createSelector(
  getCategoryGames,
  (categoryGames) => categoryGames.favoriteGames?.products || []
)

export const getRecentlyPlayedGamesSelector = createSelector(
  getCategoryGames,
  (categoryGames) => categoryGames.recentlyPlayedGames?.products || []
)

export const getRecentlyPlayedGamesIsLoadedSelector = createSelector(
  getCategoryGames,
  (categoryGames) => categoryGames.isSuccess
)

export const isCategoryGamesLoadedSelector = createSelector(
  getCategoryGames,
  (favoriteGames) => favoriteGames.isSuccess
)

export const isFavoriteGameSelector = createSelector(
  getFavoriteGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => hasGameWithCode(games, gameCode)
)

export const getRecentlyPlayedGamesWithLengthSelector = createSelector(
  getRecentlyPlayedGamesSelector,
  (_: ReduxState, gameTilesInRow: number) => gameTilesInRow,
  (recentlyPlayedGames, gameTilesInRow) =>
    recentlyPlayedGames ? getVisibleGames(recentlyPlayedGames, gameTilesInRow) : []
)

export const isRecentlyPlayedGameSelector = createSelector(
  getRecentlyPlayedGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => hasGameWithCode(games, gameCode)
)

export const isMostRecentlyPlayedGameSelector = createSelector(
  getRecentlyPlayedGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => games?.[0]?.code === gameCode
)

export const getFilteredRecommendedAndNewCategorySelector = (currentGameId: string) =>
  createSelector(
    () => currentGameId,
    getRecommendedGamesCategory,
    getNewGamesCategory,
    (_currentGameId, recommendedCategory, newCategory) => {
      const filteredRecommendedCategory = currentGameId
        ? filterOutCurrentGame(_currentGameId, recommendedCategory)
        : recommendedCategory

      return [filteredRecommendedCategory, newCategory]
    }
  )
