import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import type { TypedThunk } from 'store'
import { arrayFilterNullable } from 'utils/array/filterNullable'
import { getGamesByCategoryCode } from 'utils/loadGameInfoGetInitialProps'

export const addFavoriteGameToStore = createAction<GameProductShort>('addFavoriteGameToStore')
export const removeFavoriteGameFromStore = createAction<string>('removeFavoriteGameFromStore')

export const addFavoriteGame =
  (game: GameProductShort): TypedThunk =>
  (dispatch, getState, { gateway }) => {
    dispatch(addFavoriteGameToStore(game))

    return gateway
      .emit<unknown>({
        type: 'AddAccountFavouriteGameRequest',
        gameCode: game.code,
      })
      .catch((error) => {
        dispatch(removeFavoriteGameFromStore(game.code))
        console.log(`Error while fetching content from, ${addFavoriteGame.name}`, error)
      })
  }

export const removeFavoriteGame =
  (game: GameProductShort): TypedThunk =>
  (dispatch, getState, { gateway }) => {
    dispatch(removeFavoriteGameFromStore(game.code))

    return gateway
      .emit<unknown>({
        type: 'RemoveAccountFavouriteGameRequest',
        gameCode: game.code,
      })
      .catch((error) => {
        dispatch(addFavoriteGameToStore(game))
        console.log(`Error while fetching content from, ${removeFavoriteGame.name}`, error)
      })
  }

export const fetchCategoryGames = createAsyncThunk('games/fetchCategoryGames', async () => {
  const response = await getGamesByCategoryCode([
    { productCategoryCode: 'favourite' },
    { productCategoryCode: 'last_played' },
    { productCategoryCode: 'recommend' },
    { productCategoryCode: 'new' },
  ])

  return arrayFilterNullable(response?.categories)
})
